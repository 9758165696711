import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout title={"404: Fant ikke siden du lette etter"}>
    <div
      style={{
        maxWidth: "1440px",
        width: "100%",
        margin: "5em auto",
        textAlign: "center"
      }}
    >
      <h1>Ojsann!</h1>
      <p>
        Vi fant desverre ikke siden du lette etter. Prøv å gå tilbake til
        startsiden
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
